import React from "react";
import { SectionHeader } from "../../styles/typography.style";
import Card from "../Card/Card";
import { Row, Col } from "react-flexbox-grid";

const Services = () => (
  <Row>
    <Col xs={12}>
      <SectionHeader as="h2">Services</SectionHeader>
    </Col>
    <Col md={4}>
      <Card icon="slash">
        <h3>Web applications</h3>
        <p>
          Modern web applications with React and other javascript UI libraries.
        </p>
      </Card>
    </Col>
    <Col md={4}>
      <Card icon="braces">
        <h3>Backend </h3>
        <p>
          Backend services and REST/ GraphQL API’s with Node.js, Scala, C#, you
          name it.
        </p>
      </Card>
    </Col>
    <Col md={4}>
      <Card icon="cloud">
        <h3>Devops</h3>
        <p>Heroku, AWS, Azure, CI & CD.</p>
      </Card>
    </Col>
  </Row>
);

export default Services;
