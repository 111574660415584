import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import "lazysizes";

import Layout from "../components/layout";
import SEO from "../components/seo";
import AnimatedCharacters from "../components/AnimatedCharacters";
import Services from "../components/Services/Services";
import Section from "../components/Section/Section.style";
import { SectionHeader } from "../styles/typography.style";
import * as S from "../styles/index.style.js";
import contactImage from "../images/vhal_1_1.jpg";

const animationOffset = 200;
const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Grid>
        <Row>
          <Col xs={12}>
            <S.Content>
              <S.Title offset={animationOffset}>
                <AnimatedCharacters offset={animationOffset}>
                  Fluubo
                </AnimatedCharacters>
              </S.Title>
              <h2>
                A solo software development consultancy.
                {/* I help
                clients build delightful modern web applications. */}
              </h2>
            </S.Content>
          </Col>
        </Row>
      </Grid>

      <Section alternative>
        <Grid>
          <Services />
        </Grid>
      </Section>
      <Section>
        <Grid>
          <Row>
            <Col xs={12}>
              <S.Contact>
                <SectionHeader>Contact</SectionHeader>
                <S.ContactImageContainer>
                  <S.ContactImage
                    data-src={contactImage}
                    className="lazyload"
                    alt="Ville Halminen"
                  />
                </S.ContactImageContainer>
                <S.ContactInfo>
                  <span>Ville Halminen</span>
                  <br />
                  <span>ville.halminen@fluubo.com</span>
                  <br />
                  <span>
                    <a
                      href="https://www.linkedin.com/in/villehalminen/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </a>
                    <> &middot; </>
                    <a
                      href="https://twitter.com/villehalminen"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Twitter
                    </a>
                    <> &middot; </>
                    <a
                      href="https://halminen.dev/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      halminen.dev
                    </a>
                  </span>
                  <br />
                  <span></span>
                </S.ContactInfo>
              </S.Contact>
            </Col>
          </Row>
        </Grid>
      </Section>
    </Layout>
  );
};

export default IndexPage;
