import React from "react";
import * as S from "./Card.style";
import Slash from "../Icons/Development/CodeSSlashLine";
import Cloud from "../Icons/Business/CloudLine";
import ReactJs from "../Icons/Logos/ReactjsLine";
import Braces from "../Icons/Development/BracesLine";

const renderIcon = icon => {
  if (icon === "slash") {
    return <Slash />;
  } else if (icon === "cloud") {
    return <Cloud />;
  } else if (icon === "react") {
    return <ReactJs />;
  } else if (icon === "braces") {
    return <Braces />;
  }
};

const Card = ({ children, icon }) => {
  return (
    <S.Card>
      {renderIcon(icon)}
      {children}
    </S.Card>
  );
};

export default Card;
