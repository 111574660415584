import styled from "styled-components";

export const Card = styled.div`
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 3rem;
  :first-of-type {
    margin-top: 1rem;
  }
  svg {
    width: 5rem;
    height: 5rem;
    fill: white;
  }
`;

export const Icon = styled.img`
  fill: white;
  width: 5rem;
  height: 5rem;
`;
