import styled from "styled-components";

export const Title = styled.h1`
  position: relative;
  margin-top: 4rem;
  margin-bottom: 0;
  top: -${p => p.offset}px;
`;

export const Contact = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;
export const ContactImageContainer = styled.div`
  margin-top: 1rem;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
`;

export const ContactInfo = styled.div`
  margin-top: 1.5rem;
  text-align: center;
  span {
    display: inline-block;
    margin-top: 0.5rem;
  }
`;

export const ContactImage = styled.img`
  height: 10rem;
  border-radius: 50%;
  .lazyload {
    opacity: 0;
  }
`;

export const Content = styled.div`
  min-height: 320px;
  width: 100%;
  h1,
  h2 {
    text-align: center;
  }
  h2 {
    margin-bottom: 3rem;
  }
`;
