import React, { useEffect } from "react";
import anime from "animejs";
import * as S from "./AnimatedCharacters.style";

const AnimatedCharacters = ({ children, offset }) => {
  useEffect(() => {
    anime({
      targets: ".animation-item",
      translateY: offset,
      delay: anime.stagger(100),
    });
    anime({
      targets: ".animation-first",
      translateY: offset,
      easing: "easeOutQuad",
      duration: 700,
      delay: 300,
    });
  });

  return (
    <span>
      {[...children].map((c, i) => (
        <S.InlineSpan
          key={i}
          style={{ display: "inline-block" }}
          className={i === 0 ? "animation-first" : "animation-item"}
        >
          {c}
        </S.InlineSpan>
      ))}
    </span>
  );
};

export default AnimatedCharacters;
